<template>
  <v-row>
    <v-col cols="12">
      <v-card class="rounded-lg" flat>
        <v-container class="pa-7">
          <div class="vpc-title">Detail Project</div>

          <v-row>
            <v-col>
              <p><b>Project Name</b></p>

              <p>{{ project.name }}</p>
            </v-col>

            <v-col>
              <p><b>Region</b></p>

              <p>{{ project.region || "-" }}</p>
            </v-col>            
          </v-row>

          <v-row>
            <v-col>
              <p><b>Service Instance</b></p>

              <p>{{ project.network_number || "-" }}</p>
            </v-col>

            <v-col>
              <p><b>Interval Billing</b></p>

              <p>
                {{ project.interval_billing_per_month }}
                {{
                  project.interval_billing_per_month > 1 ? "Months" : "Month"
                }}
              </p>
            </v-col>            
          </v-row>

          <v-row>
            <v-col>
              <p><b>Invoice Due Periode</b></p>

              <p>
                {{ project.invoice_due_periode_per_day }}
                {{ project.invoice_due_periode_per_day > 1 ? "Days" : "Day" }}
              </p>
            </v-col>

            <v-col>
              <p><b>Payment Method</b></p>

              <p>
                {{
                  project.payment_method === "postpaid"
                    ? formatPostpaidType(project.postpaid_type)
                    : uppercaseFirstLetter(project.payment_method)
                }}
              </p>
            </v-col>
            
          </v-row>

          <v-row>
            <v-col>
              <p><b>Activation Date</b></p>

              <p>
                {{
                  project.service_activation_date
                    ? $moment(project.service_activation_date).format(
                        "DD/MM/YYYY"
                      )
                    : "-"
                }}
              </p>
            </v-col>
            <v-col>
              <p><b>VAT ID</b></p>
              <p> {{ vatid }} </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <p><b>Description Project</b></p>

              <p>{{ project.description }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <p><b>Document Contract</b></p>

              <beat-loader
                v-if="docLoading"
                color="gray"
                size="10px"
                class="mr-2"
              />

              <template v-else>
                <a
                  v-if="project.doc_contract && doc_contract_base64"
                  :download="filename"
                  :href="filelink"
                  class="d-flex justify-content-center align-center"
                >
                  <img src="/doc.png" alt="doc" class="mr-4" />

                  {{ filename }}

                  <img src="/download.png" alt="download" class="ml-4" />
                </a>

                <p v-else>-</p>
              </template>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka Flexi</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headers"
                :items="listDekaFlexi"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka VPN</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersListVpn"
                :items="listDekaVpn"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka Rock</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersdekarock"
                :items="listDekaRock"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka Harbor</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersdekaharbor"
                :items="listDekaHarbor"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka Box</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersdekabox"
                :items="listDekaBox"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka DNS</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersdns"
                :items="listdns"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            class="mt-4 mb-4"
            :style="{ borderTop: '1px solid lightgray' }"
          />

          <div class="vpc-title">Deka NFS</div>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="headersnfs"
                :items="listnfs"
                :items-per-page="pagination.itemsPerPage"
                :server-items-length="pagination.count"
                :options.sync="pagination"
                class="elevation-0"
              >
                <!-- pagination -->
                <!-- <template v-slot:footer="{ props }">
                  <custom-footer-datatable :props="props" />
                </template> -->

                <!-- pagination -->
                <template v-slot:footer="{ props }">
                  <custom-footer-datatable
                    v-if="
                      (pagination && pagination.count > 10) ||
                      (pagination && pagination.count === 10)
                    "
                    @input="
                      (val) => {
                        pagination = { ...pagination, ...val };
                      }
                    "
                    :props="props"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import localstorage from "@/lib/localstorage";
import api from "@/lib/api";

export default {
  props: {
    vatid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      docLoading: false,
      doc_contract_base64: null,
      headers: [
        { text: "Service Type", value: "type_name", sortable: false },
        { text: "Name", value: "instance_name", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listDekaFlexi: [],
      headersListVpn: [
        { text: "Service Type", value: "service_name", sortable: false },
        { text: "Name", value: "instance_name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listDekaVpn: [],
      headersdekarock: [
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listDekaRock: [],
      headersdekaharbor: [
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listDekaHarbor: [],
      headersdekabox: [
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "default_region", sortable: false },
      ],
      listDekaBox: [],
      headersnfs: [
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listnfs: [],
      headersdns: [
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Service Instance", value: "network_number", sortable: false },
      ],
      listdns: [],
      count: 0,
      totalpage: 0,
      countListVpn: 0,
      totalpageListVpn: 0,
      countListRock: 0,
      totalpageListRock: 0,
      countHarbor: 0,
      totalpageHarbor: 0,
      countDekaBox: 0,
      totalpageDekaBox: 0,
      countDekaNfs: 0,
      totalpageDekaNfs: 0,
      countDekaDns: 0,
      totalpageDekaDns: 0,
      paginationDekaFlexi: {
        count: 0,
        page: 1,
        totalpage: 0,
        itemsPerPage: 10,
      },
      pagination: {
        count: 3,
        page: 1,
        totalpage: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    project() {
      return JSON.parse(localstorage.getItem("currentProj"));
    },
    filename() {
      const { project } = this;

      return project.doc_contract ? project.doc_contract.split("/")[1] : "-";
    },
    filelink() {
      const { project, formatFile, doc_contract_base64 } = this;
      const fileFormat = project.doc_contract
        ? formatFile(project.doc_contract.split(".").reverse()[0])
        : "image/jpg";

      // return data:image/png;base64,asdasd...
      return "data:" + fileFormat + ";base64," + doc_contract_base64;
    },
  },
  mounted() {
    this.getDocBase64();
    this.getListService();
    this.getListServiceVpn();
    this.getListServiceDekaRock();
    this.getListServiceDekaHarbor();
    this.getListServiceDekaBox();
    this.getListServiceNfs();
    this.getListServiceDns();
  },
  methods: {

    formatFile(format) {
      switch (format) {
        case "png":
          return "image/png";

        case "pdf":
          return "application/pdf";

        default:
          return "image/jpg";
      }
    },
    uppercaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatPostpaidType(type) {
      return type === "fixed" ? "Postpaid Fixed" : "Postpaid Usage Based";
    },
    async getDocBase64() {
      this.docLoading = true;

      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("/user/project/doc-contract/" + projid);

          
          const { doc_contract_base64 } = res.data;

          this.doc_contract_base64 = doc_contract_base64;
          
          // this.project = res.data;
          // console.log("getDocBase64", res.data);
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying document contract";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }

      this.docLoading = false;
    },
    async getListService() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("/user/project/list-service/paginated?project_id="+projid+"&page=1&limit=10");
          
          const { count } = res.data.total_rows;
          const { totalpage } = res.data.total_pages;
          this.listDekaFlexi = res.data.rows;
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceVpn() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("/vpn-services/api/v1/user/project/list-service-vpn/paginated?project_id="+projid+"&page=1&limit=10");

          const { countListVpn } = res.data.total_rows;
          const { totalpageListVpn } = res.data.total_pages;
          this.listDekaVpn = res.data.rows;
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceDekaRock() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("dekarock/v1/dekarock/cluster?project_id="+projid+"&page=1&limit=10");

          const { countListRock } = res.paginate.total_data;
          const { totalpageListRock } = res.paginate.total_page;
          this.listDekaRock = res.data;
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceDekaHarbor() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("harbor/v1/deka-harbor/project/list-service-harbor/paginated?project_id="+projid+"&page=1&limit=10");
          
          const { countHarbor } = res.data.total_rows;
          const { totalpageHarbor } = res.data.total_pages;
          this.listDekaHarbor = res.data.rows;
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceDekaBox() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("user/object-storages/list-deka-box/paginated?project_id="+projid+"&page=1&limit=10");
          
          const { countDekaBox } = res.data.total_rows;
          const { totalpageDekaBox } = res.data.total_pages;
          this.listDekaBox = res.data.rows;
        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceNfs() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("nfs/v1/main/list?project_id="+projid+"&page=1&limit=10");
          
          const { countDekaNfs } = res.paginate.total_data;
          const { totalpageDekaNfs } = res.paginate.total_page;
          this.listnfs = res.data;
          console.log('test deka nfs')

        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
    async getListServiceDns() {
      
      const projid =
        this.project && this.project !== "undefined" ? this.project.id : "";

      if (projid) {
        try {
          const res = await api.GET("dekadns/api/v1/dns/list?limit=10&page=0&sort=created_at");
          
          const { countDekaDns } = res.data.total_rows;
          const { totalpageDekaDns } = res.data.total_by_project;
          this.listdns = res.data.rows;
          console.log('test deka dns')

        } catch (err) {
          let errmsg =
            "Sorry, an error occurred while displaying";
          const thereIsErrorMessage = err && err.response && err.response.data;

          if (thereIsErrorMessage && err.response.data.message) {
            errmsg = err.response.data.message;
          }

          if (
            thereIsErrorMessage &&
            err.response.data.data !== "record not found"
          ) {
            this.$store.dispatch("HOMEPAGE/showErrorToast", errmsg);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.vpc-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}
</style>
